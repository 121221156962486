<template>
  <Page full>
    <lead-body />
  </Page>
</template>

<script>
import LeadBody from '../bodies/Leads.vue'
import PageMixin from '../mixins/Page'

export default {
  mixins: [PageMixin],
  components: {
    LeadBody
  },
  data() {
    return {
      startingTab: 'Leads'
    }
  }
}
</script>
