<template>
  <grid
    type="client"
    title="Leads"
    :multiple="true"
    @loaded="updateWaitTimes"
    :permanentFilters="{
      client_count_quotes: '<1'
    }"
    :showDefaultFilterPresets="false"
    :filterPresets="gridPresets"
    :isMain="isMain"
    :filters="{
      client_time_created: `>=${this.freshCutoff}`,
      client_status: '!i&&!d&&!x&&!h'
    }"
    :filterText="{
      client_time_created: 'Recent'
    }"
    :order="[['client_time_created', 'DESC']]"
    :visible="[
      'client_preview',
      'client_status',
      'client_time_created',
      'client_owner',
      'nextSteps'
    ]"
  />
</template>

<script>
import Grid from '@/components/ui/Grid/Grid.vue'
import BodyMixin from '../mixins/Body'

export default {
  data() {
    return {
      waitTime: 0,
      waitTimeAgo: 0,
      industryAverage: 1000 * 60 * 60 * 4,
      warning: 1000 * 60 * 60 * 4,
      leadListPresets: c.getConstructor('client').leadListPresets
    }
  },
  computed: {
    freshCutoff() {
      return new Date().valueOf() - 1000 * 60 * 60 * 24 * 2
    },
    performance() {
      if (this.waitTime === 0) return 'thumb'
      if (this.waitTime <= this.industryAverage / 2) return 'stellar'
      else if (this.waitTime <= this.industryAverage * 0.9) return 'good'
      else if (this.waitTime <= this.industryAverage * 1.1) return 'average'
      return 'poor'
    },
    alertClass() {
      if (this.waitTime <= this.industryAverage) {
        return 'success'
      } else if (this.waitTime <= this.warning) {
        return 'warning'
      }
      return 'danger'
    },
    gridPresets() {
      return [
        {
          title: 'Fresh',
          description: 'Recent leads that need to be acted on.',
          filters: {
            client_time_created: `>=${this.freshCutoff}`,
            client_status: '!i&&!d&&!x&&!h'
          },
          filterText: {
            client_time_created: 'Recent'
          }
        },
        {
          title: 'Stale',
          description: 'Leads that have been waiting a long time.',
          filters: {
            client_time_created: `<${this.freshCutoff}`,
            client_status: '!i&&!d&&!x&&!h'
          },
          filterText: {
            client_time_created: 'A bit old'
          }
        },
        {
          title: 'Abandoned',
          description: 'Previous leads that went nowhere.',
          filters: {
            client_status: 'i'
          },
          filterText: {
            client_status: 'Inactive'
          }
        },
        ...this.leadListPresets
      ]
    }
  },
  methods: {
    updateWaitTimes(set) {
      this.waitTime =
        set.reduce((acc, item) => acc + (item.client_time_waiting || 0), 0) / (set.length || 1)
      this.waitTimeAgo = new Date().valueOf() - this.waitTime
    }
  },
  mixins: [BodyMixin],
  components: {
    Grid
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
